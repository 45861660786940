/* TODO
[ ] Add support for image upload to server
[ ] Follow best practices for reducing size
*/

const Noodl = require("@noodl/noodl-sdk");
import ReactQuill from "react-quill";
import { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css"; // import styles
import ImageResize from "quill-image-resize";
import undoIcon from "quill/assets/icons/undo.svg";
import redoIcon from "quill/assets/icons/redo.svg";

Quill.import("ui/icons")["undo"] = undoIcon;
Quill.import("ui/icons")["redo"] = redoIcon;

Quill.register("modules/imageResize", ImageResize);

class MyCustomReactComponent extends React.Component {
	constructor(props) {
		super(props);
		this.quillRef = null; // Quill instance
		this.reactQuillRef = null; // ReactQuill component
		this.plaintextOut = ""; // Plaintext content

		this.modules = {
			toolbar: {
				container: [
					[{ header: 1 }, { header: 2 }],
					["bold", "italic", "underline", "strike"],
					[{ list: "ordered" }, { list: "bullet" }, { align: [] }],
					[{ indent: "-1" }, { indent: "+1" }],
					[{ color: [] }, { background: [] }],
					["image"],
					["clean"],
					["undo", "redo"],
				],
				handlers: {
					undo: function () {
						this.quill.history.undo();
					},
					redo: function () {
						this.quill.history.redo();
					},
				},
			},
			clipboard: {
				matchVisual: true,
			},
			history: {
				// Enable the undo and redo module
				delay: 200,
				maxStack: 50,
				userOnly: false,
			},
			imageResize: {
				displaySize: true,
				displayStyles: {
					backgroundColor: "black",
					border: "none",
					color: "white",
				},
				modules: ["Resize", "DisplaySize"],
			},
		};
	}

	componentDidMount() {
		this.attachQuillRefs();
		this.overrideDropBehavior();
		this.addFormatsChangeListener();
		// Delay the call to triggerInitialContent
		setTimeout(() => {
			this.triggerInitialContent();
		}, 0);
	}

	componentDidUpdate(prevProps) {
		this.attachQuillRefs();
		this.addFormatsChangeListener();
		if (this.props.deltaIn !== prevProps.deltaIn) {
			this.quillRef.setContents(
				this.props.deltaIn ? JSON.parse(this.props.deltaIn) : {}
			);
			// Delay the call to triggerInitialContent
			setTimeout(() => {
				this.triggerInitialContent();
			}, 0);
		}
	}

	// Add this method
	addFormatsChangeListener = () => {
		if (this.quillRef) {
			this.quillRef.on("formats", this.handleFormatsChange);
		}
	};

	// Add this method
	handleFormatsChange = () => {
		const content = this.quillRef.getContents();
		const html = this.quillRef.root.innerHTML;
		this.handleChange(content, null, "api", this.quillRef);
	};

	triggerInitialContent = () => {
		// Check if this.quillRef is not null and is fully initialized
		if (this.quillRef && this.quillRef.root) {
			const content = this.quillRef.getContents();
			const html = this.quillRef.root.innerHTML;
			this.handleChange(content, null, "api", this.quillRef);
		}
	};

	attachQuillRefs = () => {
		if (typeof this.reactQuillRef.getEditor !== "function") return;
		this.quillRef = this.reactQuillRef.getEditor();
	};

	overrideDropBehavior = () => {
		const editor = this.reactQuillRef.getEditor();
		const container = editor.container;

		container.addEventListener("dragover", (event) => {
			event.preventDefault();
		});

		container.addEventListener("drop", (event) => {
			event.preventDefault();

			const files = Array.from(event.dataTransfer.files);
			files.forEach((file) => {
				const reader = new FileReader();

				reader.addEventListener("load", () => {
					const range = this.quillRef.getSelection();
					const base64ImageSrc = reader.result;
					this.quillRef.insertEmbed(
						range.index,
						"image",
						base64ImageSrc
					);
				});

				reader.readAsDataURL(file);
			});
		});
	};

	handleChange = (content, delta, source, editor) => {
		if (source === "user" || source === "api") {
			// 'api' indicates programmatic change
			const newContent = JSON.stringify(editor.getContents());
			const newHtmlContent = editor.getHTML(); // Get the HTML content
			const newPlaintextContent = editor.getText(); // Get the plaintext content
			this.props.onDeltaChange && this.props.onDeltaChange(newContent);
			this.props.deltaOut && this.props.deltaOut(newContent);
			this.props.htmlOut && this.props.htmlOut(newHtmlContent); // Emit HTML content
			this.props.plaintextOut &&
				this.props.plaintextOut(newPlaintextContent); // Emit plaintext content
		}
	};

	render() {
		const style = {
			height: this.props.height, // use height prop
			minHeight: this.props.minHeight, // use minHeight prop
			maxHeight: this.props.maxHeight, // use maxHeight prop
			paddingBottom: this.props.paddingBottom, // use paddingBottom prop
			width: "100%",
			backgroundColor: "white",
		};

		return (
			<ReactQuill
				ref={(el) => {
					this.reactQuillRef = el;
				}}
				style={style}
				modules={this.modules}
				value={this.props.deltaIn ? JSON.parse(this.props.deltaIn) : {}}
				onChange={this.handleChange}
			/>
		);
	}
}

const MyCustomReactComponentNode = Noodl.defineReactNode({
	name: "wysiwyg-editor",
	category: "UI Elements",
	getReactComponent() {
		return MyCustomReactComponent;
	},
	inputProps: {
		deltaIn: {
			type: "string",
			default: JSON.stringify({}),
			set(newValue, component) {
				component.setProps({ deltaIn: newValue });
			},
		},
		height: {
			type: "string",
			default: "100%",
			set(newValue, component) {
				component.setProps({ height: newValue });
			},
		},
		minHeight: {
			type: "string",
			default: "0",
			set(newValue, component) {
				component.setProps({ minHeight: newValue });
			},
		},
		maxHeight: {
			type: "string",
			default: "none",
			set(newValue, component) {
				component.setProps({ maxHeight: newValue });
			},
		},
		paddingBottom: {
			type: "string",
			default: "42px",
			set(newValue, component) {
				component.setProps({ paddingBottom: newValue });
			},
		},
	},

	outputProps: {
		deltaOut: {
			type: "string",
			displayName: "Delta Out",
			group: "Outputs",
			get(component) {
				return component.props.deltaOut;
			},
		},
		htmlOut: {
			// New output prop for HTML content
			type: "string",
			displayName: "HTML Out",
			group: "Outputs",
			get(component) {
				return component.props.htmlOut;
			},
		},
		plaintextOut: {
			type: "string",
			displayName: "Plaintext Out",
			group: "Outputs",
			get(component) {
				return component.props.plaintextOut;
			},
		},
	},
});

Noodl.defineModule({
	reactNodes: [MyCustomReactComponentNode],
	nodes: [],

	setup() {
		// this is called once on startup
	},
});
